import Dropzone from 'react-dropzone'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { imageActions } from '../_actions'
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

class ImageUploader extends Component {
  constructor(props) {
    super(props);

      this.state = {};
      this.handleDrop = this.handleDrop.bind(this);
    }

    handleDrop(files) {
      // Push all the axios request promise into a single array
      const uploaders = files.map(file => {
        // Initial FormData
        const formData = new FormData();
        formData.append("file", file);
        formData.append("tags", `codeinfuse, medium, gist`);
        formData.append("upload_preset", "pvhilzh7"); // Replace the preset name with your own
        formData.append("api_key", "791281797998197"); // Replace API key with your own Cloudinary key
        formData.append("timestamp", (Date.now() / 1000) | 0);

        if (this.props.task_id) {
          this.props.dispatch(imageActions.upload(formData, this.props.task_id));
        }
        else {
          this.props.dispatch(imageActions.upload(formData, null));
        }

      });
    }
  render() {
    const images = this.props.images;
    const maxSize = 10485760;
    console.log("DRAFT IN PROPS")
    console.log(images)
    const dropzoneStyle = {
        width  : "100%",
        height : "20%",
        border : "1px solid black"
    };
    const { t } = this.props;

    return (
      <Dropzone
        onDrop={this.handleDrop.bind(this)}
        accept="image/png, image/jpeg"
        minSize={0}
        maxSize={maxSize}
        multiple
      >
        {({getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles}) => {
          console.log(rejectedFiles);
          const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
          console.log(images);
          console.log(this.props);
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {!isDragActive && t('Click here or drop a file to upload!')}
              {isDragReject && t("File type not accepted, sorry!")}
              {isFileTooLarge && (
                <div className="text-danger mt-2">
                  {t("One of you files is too large. Maximum size is 5 MB.")}
                </div>
              )}
              <br/>
              {images &&
              images.map((image, index) =>
                  <img key={image.url} src={image.url} width="25%" height="25%"></img>
                )
              }
            </div>
          )}
        }
      </Dropzone>
    );
  }
}

const mapStateToProps = (state, props) => ({
  images: _.filter(state.images.items, { 'task_id': props.task_id?parseInt(props.task_id):null } )
});


const connectedImageUploader = withTranslation("translation")(connect(mapStateToProps)(ImageUploader));
export { connectedImageUploader as ImageUploader };
