import { villageConstants } from '../_constants';

export function villages(state = {items: []}, action) {
  switch (action.type) {
    case villageConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case villageConstants.GETALL_SUCCESS:
      return {
        items: action.villages.data
      };
    case villageConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case villageConstants.DELETE_REQUEST:
      // add 'deleting:true' property to village being deleted
      return {
        ...state,
        items: state.items.map(village =>
          village.id === action.id
            ? { ...village, deleting: true }
            : village
        )
      };
    case villageConstants.DELETE_SUCCESS:
      // remove deleted village from state
      return {
        items: state.items.filter(village => village.id !== action.id)
      };
    case villageConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to village
      return {
        ...state,
        items: state.items.map(village => {
          if (village.id === action.id) {
            // make copy of village without 'deleting:true' property
            const { deleting, ...villageCopy } = village;
            // return copy of village with 'deleteError:[error]' property
            return { ...villageCopy, deleteError: action.error };
          }

          return village;
        })
      };
    case villageConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (village, index) => {
            if(village.value === action.edit.rowId){
              return { ...village, [action.edit.dataField]:action.edit.newValue }
            }
            return village
          })
      };
      case villageConstants.CREATE_SUCCESS:
        console.log("ACTION")
        console.log(action)
        return {
          ...state,
          items: [
              ...state.items,
              action.id
          ]
        };
      default:
        return state
    }
}
