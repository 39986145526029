import { userConstants } from '../_constants';
import { userService } from '../_services';
import { history } from '../_helpers';
import { error as toastError, success as toastSuccess } from 'react-toastify-redux';

export const userActions = {
    login,
    logout,
    forgotPassword,
    changePassword,
    resetPassword,
    getAll,
    update,
    retrieveUser,
    createNew,
    delete: _delete
};

function createNew(user)  {
    return dispatch => {
        dispatch(request(user));

        userService.create(user)
            .then(
                user => {
                    dispatch(success(user.data.value));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: userConstants.CREATE_REQUEST, user } }
    function success(id) { return { type: userConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.CREATE_FAILURE, id, error } }
}

function forgotPassword(user) {
    return dispatch => {
        console.log("DISPATCHING")
        console.log(user)
        console.log(user.email)
        dispatch(request(user));

        userService.forgotPassword(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(toastSuccess('Instruction to reset your password have been sent to you.'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.response.data.error));
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.FORGOT_PASSWORD_FAILURE, error } }
}

function resetPassword(user) {
    return dispatch => {
        console.log("DISPATCHING")
        console.log(user)
        console.log(user.email)
        dispatch(request(user));

        userService.resetPassword(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(toastSuccess('Successfully reset your password.'));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error.response.data.error));
                }
            );
    };

    function request(user) { return { type: userConstants.RESET_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.RESET_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

function changePassword(user) {
    return dispatch => {
        console.log("DISPATCHING")
        console.log(user)
        console.log(user.email)
        dispatch(request(user));

        userService.changePassword(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/');
                    dispatch(toastSuccess('Successfully changed your password.'));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error.response.data.error));
                }
            );
    };

    function request(user) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

function login(email, password) {
    return dispatch => {
        dispatch(request({ email }));
        console.log("AFTER DISPATCH")
        userService.login(email, password)
            .then(
                user => {
                  console.log(user)
                    if(user && user.data && user.data.response && user.data.response.user && user.data.response.user.authentication_token)
                    {
                      console.log("THEN USER")
                      localStorage.setItem('user', JSON.stringify(user.data.response.user));
                      dispatch(success(user.data.response.user));
                    }
                    else {
                      console.log("THEN ERROR")
                      dispatch(failure(user));
                      //dispatch(toastError(user.message));
                    }

                },
                error => {
                    console.log("THEN ERROR")
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function retrieveUser(user) {
    return dispatch => {
        dispatch(success(user));
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users))
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return userService.update(edit)
            .then(
                user => {
                    dispatch(success(user));
                    return user;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: userConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: userConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.UPDATE_FAILURE, id, error } }
}
