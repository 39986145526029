import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';
import { hotelActions } from '../_actions';
import BootstrapTable from 'react-bootstrap-table-next';
import { history } from '../_helpers';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { FormikSelect as Select } from '../_components';
import ReactSelect from 'react-select'
import { withTranslation } from 'react-i18next';

class NewHotelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.createNewHotel = this.createNewHotel.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  createNewHotel(values, { props, setSubmitting }) {
      this.props.dispatch(hotelActions.createNew(values))
      setSubmitting(false);
      this.toggle();
      // resetForm();
  }

  render() {

    const columns = [{
      dataField: 'label',
      text: 'Hotel'
    }];
    const { t } = this.props;

    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>Add an hotel</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={this.createNewHotel}
            initialValues={{
              label: ""
          	}}
            validationSchema={Yup.object().shape({
              label: Yup.string()
                .required('Hotel name is required.')
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Label for="label">{t("Hotel")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.label && touched.label} tag={Field} type="text" value={values.label} name="label" id="label" />
                  {errors.label && touched.label && <div className="input-feedback" style={{color:"red"}}>{errors.label}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>{t("Add")}</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>{t("Cancel")}</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { hotels } = state;
  return {
      hotels: hotels
  };
}

const connectedNewHotelModal = withTranslation("translation")(connect(mapStateToProps)(NewHotelModal));
export { connectedNewHotelModal as NewHotelModal };
