import React, { Component } from 'react';
import '../css/App.css';
import { history } from '../_helpers';
import { userActions } from '../_actions';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { LoginPage, ChangePasswordPage, ForgotPasswordPage, ResetPasswordPage, LogoutPage } from '../AuthPages';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/browser';
import { ToastContainer, error as toastError, success as toastSuccess  } from 'react-toastify-redux';
import { Navigation, PrivateRoute, PrivateAdminRoute, LoginRoute } from '../_components';
import { connect } from 'react-redux';
import { AllTasks } from '../Tasks';
import { AllHotelCards } from '../Hotels';
import { AllUsers } from '../Users';
import { AllCategories } from '../Categories';
import { withTranslation } from 'react-i18next';

Sentry.init({
  dsn: "https://6c6750aed2c241bc989e641c9cda1601@sentry.io/1358046"
 });

class App extends Component {
  constructor(props) {
      super(props);

      const { dispatch } = this.props;
  }

  render() {

    const lsUser = JSON.parse(localStorage.getItem('user'));

    return (
        <div>
          {alert.message &&
              <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <Switch>
            <PrivateRoute exact path="/" component={() => <Redirect to="/hotels" />}/>
            <PrivateRoute exact path="/tasks" component={AllTasks} />
            <PrivateRoute exact path="/tasks/:hotel_id" component={AllTasks} />
            <PrivateRoute exact path="/hotels" component={AllHotelCards} />
            <PrivateRoute exact path="/users" component={AllUsers} />
            <PrivateRoute exact path="/categories" component={AllCategories} />
            <LoginRoute path="/login" component={LoginPage} />
            <Route path="/forgot_password" component={ForgotPasswordPage} />
            <PrivateRoute path="/logout" component={LogoutPage} />
            <LoginRoute path="/reset_password/:passwordResetCode" component={ResetPasswordPage} />
            <PrivateRoute lsUser={ lsUser } path="/change_password" component={ChangePasswordPage} />
          </Switch>
          <ToastContainer />
        </div>
    );
  }
}

function mapStateToProps(state) {
    const { alert } = state;
    return {
        alert,
        sessionData: state.session,
        isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
    };
}

const connectedApp = withTranslation()(connect(mapStateToProps)(App));
export { connectedApp as App };
