import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { taskActions } from '../_actions';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik'
import { FormikSelect as Select, RadioButton, RadioButtonGroup, ImageUploader  } from '../_components';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

class NewTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.createNewtask = this.createNewTask.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  createNewTask(values) {
      this.props.handleSubmit({...values, images:this.props.images})
      this.toggle();
      // resetForm();
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>{t("Make a task request")}</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={values => {
              this.createNewTask(values);
            }}
            initialValues={{
              category_id: this.props.categories && this.props.categories[0] && this.props.categories[0].value,
              description: "",
              degree_of_urgency: null,
              hotel_id: parseInt(this.props.hotelId),
              images: []
          	}}
            validationSchema={Yup.object().shape({
              category_id: Yup.number()
                .required(t('You must pick a category.')),
              description: Yup.string()
                .required(t('Please describe the issue.')),
              degree_of_urgency: Yup.number()
                .required(t('Please tell us how urgent this issue is.')),
              hotel_id: Yup.number()
                .required(t('The building is required.'))
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label={t("Category")} options={this.props.categories.items} name="category_id" id="category_id" value={values.category_id}/>
                  {errors.category_id && touched.category_id && <div className="input-feedback" style={{color:"red"}}>{errors.category_id}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="description">{t("Description")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.description && touched.description} tag={Field} type="text" value={values.description} name="description" id="description" />
                  {errors.description && touched.description && <div className="input-feedback" style={{color:"red"}}>{errors.description}</div>}
                </FormGroup>
                <Label
                  htmlFor="image-uploader"
                  style={{ display: 'block', marginTop: '.5rem' }}
                >
                  Pictures
                </Label>
                <ImageUploader onChange={(e) => {handleChange(e)}} values={values.images} task_id={this.state.task_id}></ImageUploader>
                <br/>
                <RadioButtonGroup
                  id="degree_of_urgency"
                  label={t("Degree of urgency")}
                  value={values.degree_of_urgency}
                  error={errors.degree_of_urgency}
                  touched={touched.degree_of_urgency}
                >
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="1"
                    label="Urgent! Need to correct immediately."
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="2"
                    label="Situation has been stabilised, needs to be fixed permanently ASAP."
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="3"
                    label="Situation has been stabilised but should get attention within the next month."
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="4"
                    label="Situation has been stabilised but should get attention within the foreseeable future."
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="5"
                    label="Suggested improvement. No rush."
                  />
                </RadioButtonGroup>
                <FormGroup>
                  <Field isDisabled={parseInt(this.props.hotelId)} handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label={t("Building")} options={this.props.hotels.items} name="hotel_id" id="hotel_id" value={values.hotel_id}/>
                  {errors.hotel_id && touched.hotel_id && <div className="input-feedback" style={{color:"red"}}>{errors.hotel_id}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>{t("Add")}</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>{t("Cancel")}</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubmit: (task) => {
      dispatch(taskActions.createNew(task));
    }
  }
}

function mapStateToProps(state, props) {
  const { users, hotels, categories, images } = state;
  return {
      categories: categories,
      users: users,
      hotels: hotels,
      images: _.filter(images.items, { 'task_id': props.task_id?parseInt(props.task_id):null } )
  };
}

const connectedNewTaskModal = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(NewTaskModal));
export { connectedNewTaskModal as NewTaskModal };
