import React from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Row, Col } from 'reactstrap';
import { userActions } from '../_actions';
import { Link } from 'react-router-dom';
import { error as toastError, success as toastSuccess } from 'react-toastify-redux';

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            password_reset_code: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        console.log("PATIENT LAYOUT PROPS")
        console.log(this.props)
        let params = this.props.match.params
        console.log(params)
        this.setState({ ["password_reset_code"]: params.passwordResetCode });
        //this.props.dispatch(recommendationActions.get(params.patientId));
      }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { password, password_reset_code } = this.state;
        if (!password) {
          this.props.dispatch(toastError("You need to provide a password."));
        }
        if (!password_reset_code) {
          this.props.dispatch(toastError("Password reset code is not yet loaded. Please wait and try again."));
        }
        if (password && password_reset_code) {
          const credentials = { password: password, password_reset_code: password_reset_code }
          console.log("TESTING CREDENTIALS")
          console.log(credentials)
          this.props.dispatch(userActions.resetPassword(credentials));
        }
    }

    render() {
        const { loggingIn } = this.props;
        const { password, submitted } = this.state;
        return (
            <div>
              <div className="background"></div>
                <div className="login_with_logo">
                    <div className="login-panel card card-primary">
                        <div className="card-body">
                            <h1 className="card-title" style={{textAlign: "center", color: "rgb(33, 150, 243)", fontWeight: 300}}>FCNQ Construction</h1>
                            <form onSubmit={this.handleSubmit} name="login_user_form" id="login_form">
                                <fieldset>
                                    <FormGroup className={(submitted && !password ? ' has-error' : '')}>
                                      <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
                                    </FormGroup>
                                    <FormGroup>
                                      <Row>
                                        <Col>
                                          <Button className="full-width" color="success">Récupérer votre mot de passe</Button>
                                        </Col>
                                        <Col>
                                          <Link to="/login"><Button className="full-width" color="secondary">Connecter</Button></Link>
                                        </Col>
                                      </Row>
                                    </FormGroup>
                                </fieldset>
                            </form>
                        </div>
                        <div style={{color:"white", textAlign:"center", width:"100%"}}><small>v1.1.1</small></div>
                    </div>
                </div>

              </div>
        );
    }
}


function mapStateToProps(state) {
    return {};
}

const connectedResetPasswordPage = connect(mapStateToProps)(ResetPasswordPage);
export { connectedResetPasswordPage as ResetPasswordPage };
