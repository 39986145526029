import { taskConstants, medicationConstants, conditionConstants, recommendationConstants } from '../_constants';

export function tasks(state = {pagination: {totalSize: 10,sizePerPage: 10,page: 1}}, action) {
  switch (action.type) {
    case taskConstants.GETALL_REQUEST:
      return {
        loading: true,
        filters: state.filters,
        pagination: state.pagination
      };
    case taskConstants.GETALL_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        filters:state.filters,
        items: action.tasks.data.tasks,
        pagination: {
            ...state.pagination,
            totalSize: action.tasks.data.totalSize,
            page: action.tasks.data.page,
            sizePerPage: action.tasks.data.sizePerPage,
            filters: action.tasks.data.filters,
        }
      };
    case taskConstants.GET_FAILURE:
      return {
        ...state,
        currenttask: {}
      };
    case taskConstants.UPDATE_SEARCH:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        pagination: {
            ...state.pagination,
            searchQuery: action.searchQuery
        }
      };
    case taskConstants.UPDATE_PAGINATION:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        pagination: {
            ...state.pagination,
            page: action.page,
            sizePerPage: action.sizePerPage
        }
      };
    case taskConstants.CREATE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: [
            ...state.items,
            action.id.data
        ]
      };
    case taskConstants.UPDATE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (task, index) => {
            if(task.task_id == action.id.data.task_id){
              return { ...action.id.data }
            }
            return task
          })
      };
    case taskConstants.DELETE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.filter(task => task.task_id !== action.id)

      };
    default:
      return state
  }
}
