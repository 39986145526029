import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { userActions } from '../_actions';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik'
import { withTranslation } from 'react-i18next';

class NewUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  createNewUser(values, { props, setSubmitting }) {
      this.props.dispatch(userActions.createNew(values))
      setSubmitting(false);
      this.toggle();
      // resetForm();
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>{t("Add a user")}</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={this.createNewUser}
            initialValues={{
              first_name: "",
              last_name: "",
              email: ""
          	}}
            validationSchema={Yup.object().shape({
              first_name: Yup.string()
                .required('The first name is required.'),
              last_name: Yup.string()
                .required('The last name is required.'),
              email: Yup.string().required('The email is required.'),
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Label for="label">{t("First Name")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.first_name && touched.first_name} tag={Field} type="text" value={values.first_name} name="first_name" id="first_name" />
                  {errors.first_name && touched.first_name && <div className="input-feedback" style={{color:"red"}}>{errors.first_name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="label">{t("Last Name")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.last_name && touched.last_name} tag={Field} type="text" value={values.last_name} name="last_name" id="last_name" />
                  {errors.last_name && touched.last_name && <div className="input-feedback" style={{color:"red"}}>{errors.last_name}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="label">{t("Email")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.email && touched.email} tag={Field} type="text" value={values.email} name="email" id="email" />
                  {errors.email && touched.email && <div className="input-feedback" style={{color:"red"}}>{errors.email}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>{t("Create")}</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>{t("Annuler")}</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
      users: users
  };
}

const connectedNewUserModal = withTranslation("translation")(connect(mapStateToProps)(NewUserModal));
export { connectedNewUserModal as NewUserModal };
