import React from 'react';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/fontawesome-free-solid';
import { taskActions } from '../_actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class SearchBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { inputValue: '' };
  }

  handleInputChange = (newValue: string) => {
  	  console.log(newValue)
      const inputValue = newValue.replace(/\W/g, '');

      this.props.dispatch(taskActions.updateSearch(inputValue));
      console.log(inputValue);

      return inputValue;
   };

  render() {
    const { t } = this.props;

    return (
		<InputGroup>
		    <Input placeholder={t("Search...")} onChange={(e) => this.handleInputChange(`${e.target.value}`)} defaultValue={this.props.searchQuery}/>
		  </InputGroup>
		)
	}
}


function mapStateToProps(state) {
    return {};
}

const connectedSearchBar = withTranslation("translation")(connect(mapStateToProps)(SearchBar));
export { connectedSearchBar as SearchBar };
