import React from 'react'
import ReactSelect, { Option, ReactSelectProps } from 'react-select'
import { FieldProps } from 'formik'

const singleValueStyle = {
  singleValue: (styles, { data }) => ({ ...styles, overflow: 'visible' }),
};

const FormikSelect: React.SFC<ReactSelectProps & FieldProps> = ({
    options,
    field,
    form,
    label,
    error,
    withHr,
    touched,
    handleBlur,
    isDisabled
  }) => (
      <div className="form-group">
        <div>
          { label &&
            <label htmlFor="color">{label}</label>
          }
          <ReactSelect
            options={options}
            name={field.name}
            value={options ? options.find(option => option.value == field.value) : {label:"failed", value:"failed"}}
            onBlur={field.onBlur}
            styles={singleValueStyle}
            onChange={(option: Option) => form.setFieldValue(field.name, option.value)}
            isDisabled={isDisabled}
          />
          {!!error &&
            touched && (
              <div style={{ color: 'red', marginTop: '.5rem' }}>{error}</div>
            )}
        </div>
        {withHr && <hr/> }
      </div>
    )

export { FormikSelect };
