import { categoryConstants } from '../_constants';

export function categories(state = {items: []}, action) {
  switch (action.type) {
    case categoryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case categoryConstants.GETALL_SUCCESS:
      return {
        items: action.categories.data
      };
    case categoryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case categoryConstants.DELETE_REQUEST:
      // add 'deleting:true' property to category being deleted
      return {
        ...state,
        items: state.items.map(category =>
          category.id === action.id
            ? { ...category, deleting: true }
            : category
        )
      };
    case categoryConstants.DELETE_SUCCESS:
      // remove deleted category from state
      return {
        items: state.items.filter(category => category.id !== action.id)
      };
    case categoryConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to category
      return {
        ...state,
        items: state.items.map(category => {
          if (category.id === action.id) {
            // make copy of category without 'deleting:true' property
            const { deleting, ...categoryCopy } = category;
            // return copy of category with 'deleteError:[error]' property
            return { ...categoryCopy, deleteError: action.error };
          }

          return category;
        })
      };
    case categoryConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (category, index) => {
            if(category.value === action.edit.rowId){
              return { ...category, [action.edit.dataField]:action.edit.newValue }
            }
            return category
          })
      };
      case categoryConstants.CREATE_SUCCESS:
        console.log("ACTION")
        console.log(action)
        return {
          ...state,
          items: [
              ...state.items,
              action.id
          ]
        };
      default:
        return state
    }
}
