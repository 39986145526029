import { categoryConstants } from '../_constants';
import { categoryService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const categoryActions = {
    getAll,
    createNew,
    update,
    delete: _delete
  };


function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        categoryService.getAll()
            .then(
                categories => {
                    console.log("THEN SUCCESS");
                    dispatch(success(categories))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: categoryConstants.GETALL_REQUEST } }
    function success(categories) { return { type: categoryConstants.GETALL_SUCCESS, categories } }
    function failure(error) { return { type: categoryConstants.GETALL_FAILURE, error } }
}

function createNew(category)  {
    return dispatch => {
        dispatch(request(category));

        categoryService.create(category)
            .then(
                category => {
                    dispatch(success(category.data));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: categoryConstants.CREATE_REQUEST, category } }
    function success(id) { return { type: categoryConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: categoryConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return categoryService.update(edit)
            .then(
                category => {
                    dispatch(success(category));
                    return category;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: categoryConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: categoryConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: categoryConstants.UPDATE_FAILURE, id, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        categoryService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: categoryConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: categoryConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: categoryConstants.DELETE_FAILURE, id, error } }
}
