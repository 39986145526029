import { authHeader, refreshHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { store } from '../_helpers';
import { error as toastError } from 'react-toastify-redux';
import { userConstants } from '../_constants';

export const userService = {
    login,
    logout,
    create,
    getAll,
    update,
    refreshToken,
    forgotPassword,
    resetPassword,
    changePassword,
    delete: _delete
};

function forgotPassword(user) {
    return axios.post('/forgot_password', user).then(handleResponse);
}


function changePassword(user) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.post('/change_password', user, requestOptions).then(handleResponse);
}


function resetPassword(user) {
    return axios.post('/reset_password', user).then(handleResponse);
}

function login(credentials) {
      console.log("ABOUT TO LOGIN AXIOS")
      return axios.post('/login', {
          email:credentials.email,
          password:credentials.password
        })
        .then(handleResponse).catch(error => {
          console.log("LOGIN ERROR")
          console.log(error.response)
          if (!error.response || !error.response.status) {
            store.dispatch(toastError("Network Error. Make sure your internet connection is working."));
          }
          else if (error.response.status === 400 || error.response.status === 401) {
            store.dispatch(toastError("Login failed. Make sure your email and password are entered correctly."));
          }
          else {
            store.dispatch(toastError("Something went wrong. Please try again later."));
          }
    });
}

function create(values) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log(values);
    return axios.put('/user', values, config).then(handleResponse).catch(error => {console.log(error.response)});
}

export function refreshToken(dispatch) {

    const requestOptions = {
        headers: refreshHeader()
    };

    var freshTokenPromise = axios.post('/refresh', null, requestOptions)
        .then(t => {
            dispatch({
                type: userConstants.DONE_REFRESHING_TOKEN,
                freshToken: t.data.access_token
            });
            var user = localStorage.getItem('user');
            user = JSON.parse(user);
            if (user.user)
            {
              user = user.user
            }
            user.authentication_token = t.data.access_token;
            localStorage.setItem('user', JSON.stringify(user));
            console.log("DONE SETTING NEW TOKEN")
            console.log(t.data.access_token)
            return t.data.access_token ? Promise.resolve(t.data.access_token) : Promise.reject({
                message: 'could not refresh token'
            });

        })
        .catch(e => {

            console.log('error refreshing token', e);

            dispatch({
                type: userConstants.DONE_REFRESHING_TOKEN
            });
            return Promise.reject(e);
        });


    console.log("FRESH TOKEN PROMISE");
    console.log(freshTokenPromise);
    dispatch({
        type: userConstants.REFRESHING_TOKEN,

        // we want to keep track of token promise in the state so that we don't try to refresh
        // the token again while refreshing is in process
        freshTokenPromise
    });

    return freshTokenPromise;
}

function logout() {
    // remove user from local storage to log user out
    console.log("REMOVE USER")
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        headers: authHeader()
    };
    console.log("BEFORE AXIOS GET ALL USERS")
    return axios.get('/users', requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}

function register(user) {
    return axios.post('/register', user).then(handleResponse);
}

function update(edit) {
    const requestOptions = {
        headers: { ...authHeader() }
    };

    return axios.post('/user', edit, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/users/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}
