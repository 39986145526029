import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { taskActions } from '../_actions';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik'
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

class ResolveTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.updateTask = this.updateTask.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  updateTask(values) {
    console.log(this);
    console.log(this.props);

    this.props.handleSubmit({...values, task_id:this.props.task_id})
    this.toggle();
    // resetForm();
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>{t("Make a task request")}</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={values => {
              this.updateTask(values);
            }}
            initialValues={{
              category_id: this.props.initialValues.category_id,
              description: this.props.initialValues.description,
              degree_of_urgency: this.props.initialValues.degree_of_urgency.toString(),
              images:this.props.initialValues.images,
              resolution_estimate:this.props.initialValues.resolution_estimate,
              hotel_id: this.props.hotel_id?this.props.hotel_id:this.props.initialValues.hotel_id,
              resolution: this.props.resolution
          	}}
            validationSchema={Yup.object().shape({
              resolution: Yup.string()
                .required('Please describe the resolution.')
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Label for="resolution">{t("Resolution")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.resolution && touched.resolution} tag={Field} type="text" value={values.resolution} name="resolution" id="resolution" />
                  {errors.description && touched.resolution && <div className="input-feedback" style={{color:"red"}}>{errors.resolution}</div>}
                </FormGroup>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubmit: (task) => {
      dispatch(taskActions.update(task));
    }
  }
}

function mapStateToProps(state, props) {
  const { users, hotels, categories, images } = state;
  return {
      categories: categories,
      users: users,
      hotels: hotels,
      images: _.filter(images.items, { 'task_id': props.task_id?parseInt(props.task_id):null } )
  };
}

const connectedResolveTaskModal = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(ResolveTaskModal));
export { connectedResolveTaskModal as ResolveTaskModal };
