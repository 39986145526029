import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
import { NewUserModal } from "../Users"
import { Row, Col, Input } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { store } from '../_helpers';
import { withTranslation } from 'react-i18next';

function onActiveChange(value, row) {

  console.log(value);
  console.log(row);

  if (value == 1 || value == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "active", newValue: value.toString(), rowId: row.id}

  store.dispatch(userActions.update(cellEdit))

}

function onAdminChange(value, row) {

  console.log(value);
  console.log(row);

  if (value == 1 || value == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "is_admin", newValue: value.toString(), rowId: row.id}

  store.dispatch(userActions.update(cellEdit))

}


function onReadOnlyChange(value, row) {

  console.log(value);
  console.log(row);

  if (value == 1 || value == true) {
    value = false;
  }
  else {
    value = true;
  }
  var cellEdit = { dataField: "is_read_only", newValue: value.toString(), rowId: row.id}

  store.dispatch(userActions.update(cellEdit))

}

function checkboxAdminFormatter(cell, row) {

  const admin = row.is_admin==true||row.is_admin==1||row.is_admin=='true'?true:false;

  return (
    <div>
      <Input type="checkbox" defaultChecked={ admin } onChange={ () => onAdminChange(row.is_admin, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

function checkboxReadOnlyFormatter(cell, row) {

  const admin = row.is_read_only==true||row.is_read_only==1||row.is_read_only=='true'?true:false;

  return (
    <div>
      <Input type="checkbox" defaultChecked={ admin } onChange={ () => onReadOnlyChange(row.is_read_only, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}

function checkboxFormatter(cell, row) {

  const active = row.active==true||row.active==1||row.active=='true'?true:false;

  return (
    <div>
      <Input type="checkbox" defaultChecked={ active } onChange={ () => onActiveChange(row.active, row)  } style={{margin: "auto", position: "relative"}} />
    </div>
  );
}



const remote={
  filter: false,
  pagination: false,
  sort: false,
  cellEdit: true
}

class AllUsers extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        users: []
      };
    }

  componentDidMount() {
    this.props.getAllUsers();
  }

  onTableChange(type, newState) {

    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    return true;
  }

  render() {
    const { t } = this.props;

    const columns = [{
      dataField: 'first_name',
      text: t('First Name')
    }, {
      dataField: 'last_name',
      text: t('Last Name')
    }, {
      dataField: 'email',
      text: t('Email')
    }, {
      dataField: 'active',
      text: t('Active'),
      editable: false,
      formatter: checkboxFormatter,
    },{
      dataField: 'is_admin',
      text: t('Administrator'),
      editable: false,
      formatter: checkboxAdminFormatter,
    },{
      dataField: 'is_read_only',
      text: t('Read Only'),
      editable: false,
      formatter: checkboxReadOnlyFormatter,
    }, {
      dataField: 'password',
      text: t('Password')
    }];

    var users = this.props.users.items;

    if(users && users.length) {
      users.sort(function(a, b){
       var nameA=a.first_name.toLowerCase(), nameB=b.first_name.toLowerCase();
       if (nameA < nameB) //sort string ascending
        return -1;
       if (nameA > nameB)
        return 1;
       return 0; //default return value (no sorting)
      });
    }

    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        <Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewUserModal buttonLabel={t("New User")} /></Col><Col md="1" xs="6"></Col><Col md="8" xs="12"></Col>
        </Row>
        <br/>
        {users && users.length > 0 &&
          <BootstrapTable bootstrap4={true} bordered={false} keyField='id' remote={remote} cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) } onTableChange={this.onTableChange.bind(this)} data={ this.props.users.items } columns={ columns } />
        }
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(userActions.update(edit));
    },
    getAllUsers: () => {
      dispatch(userActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        users: state.users
    }
}

const connectedAllUsers = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(AllUsers));
export { connectedAllUsers as AllUsers };
