export const villageConstants = {

    GETALL_REQUEST: 'VILLAGES_GETALL_REQUEST',
    GETALL_SUCCESS: 'VILLAGES_GETALL_SUCCESS',
    GETALL_FAILURE: 'VILLAGES_GETALL_FAILURE',

    DELETE_REQUEST: 'VILLAGES_DELETE_REQUEST',
    DELETE_SUCCESS: 'VILLAGES_DELETE_SUCCESS',
    DELETE_FAILURE: 'VILLAGES_DELETE_FAILURE',

    CREATE_REQUEST: 'VILLAGE_CREATE_REQUEST',
    CREATE_SUCCESS: 'VILLAGE_CREATE_SUCCESS',
    CREATE_FAILURE: 'VILLAGE_CREATE_FAILURE',
    UPDATE_REQUEST: 'VILLAGE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'VILLAGE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'VILLAGE_UPDATE_FAILURE',

};
