import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store, history } from '../_helpers';

export const taskService = {
    getAll,
    update,
    create,
    delete: _delete
};

function getAll(page, sizePerPage, filters) {

    if (page === undefined)
    {
        page = 1;
    }

    if (sizePerPage === undefined)
    {
        sizePerPage = 10;
    }

    if (filters === undefined || filters === "")
    {
        filters = {};
    }

    const config = {
      headers: { ...authHeader() }
    };

    const searchQueryAndFilters = {
      page: page,
      sizePerPage: sizePerPage,
      filters: filters
    }

    return axios.post('/search_tasks', {...searchQueryAndFilters}, config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load tasks. Please try again later.")); throw "failure";});
}

function get(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/task/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}

function update(edit) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/task', edit, config).then(handleResponse).catch(error => {console.log(error.response)});
}

function create(values) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log(values);
    return axios.put('/task', values, config).then(handleResponse).catch(error => {console.log(error.response)});
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, reason) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/task/'+id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}
