import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input } from 'reactstrap';
import { taskActions } from '../_actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/fontawesome-free-solid'
import { withTranslation } from 'react-i18next';

class DeleteTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      reason: ""
    };

    this.toggle = this.toggle.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(event) {
      this.setState({reason: event.target.value});
    }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteTask() {
    this.props.delete(this.props.task_id, this.state.reason);
    this.toggle();
  }

  render() {
    const { t } = this.props;

    return (
      <div>
        <Button color="danger" className="full-width" onClick={this.toggle}><FontAwesomeIcon icon={faTimes} color="white" size="lg" /></Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>{t("Delete Task")}</ModalHeader>
          <ModalBody>
            <Label for="reason">{t("Voulez-vous vraiment supprimer cette tâche?")}</Label>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button color="danger" onClick={this.deleteTask}>{t("Supprimer")}</Button>
              </Col>
              <Col xs="6" className="text-center" >
                <Button color="secondary" onClick={this.toggle}>{t("Annuler")}</Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    delete: (task_id, reason) => {
      dispatch(taskActions.delete(task_id, reason))
    }
  }
}

const connectedDeleteTaskModal = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(DeleteTaskModal));
export { connectedDeleteTaskModal as DeleteTaskModal };
