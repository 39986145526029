import React from 'react';
import { connect } from 'react-redux';
import { hotelActions, villageActions } from '../_actions';
import { NewHotelModal } from "../Hotels";
import { Card } from "../_components";
import ReactSelect from "react-select";
import { Row, Col, CardColumns } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTranslation } from 'react-i18next';

class AllHotelCards extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        hotels: []
      };
      this.onVillageChange = this.onVillageChange.bind(this);
      this.colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            color: data.urg_tasks < 2 ? 'green' : data.urg_tasks < 4 ? "orange" : "red",
          };
        },
      };
    }

  componentDidMount() {
    this.props.getAllHotels();
    this.props.getAllVillages();
  }

  onVillageChange(village) {
    this.setState({village:village.value, hotels:this.state.hotels})
    return true;
  }

  onTableChange(type, newState) {
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    return true;
  }

  render() {
    const { t } = this.props;

    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        <h3>{t("Select the building in order to create a task")}</h3>
        <br/>
          <>
            <Row>
              <Col md="3" xs="6" style={{marginBottom: "10px"}}><ReactSelect styles={this.colourStyles} options={this.props.villages.items} onChange={this.onVillageChange} getOptionLabel={option => `${option.label}: ${option.num_tasks} active tasks`}/></Col><Col md="1" xs="6"></Col>
            </Row>
            <br/>
          </>

        <br/>
        <CardColumns>
          { this.props.hotels && this.props.hotels.items && this.props.hotels.items.filter(hotel => hotel.village === this.state.village).map((item, key) =>
              <Card title={item.label} value={item.value} picture={item.picture} num_tasks={item.num_tasks} urg_tasks={item.urg_tasks}/>
          ) }
        </CardColumns>
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(hotelActions.update({...edit, rowId:edit.rowId.value}));
    },
    getAllHotels: () => {
      dispatch(hotelActions.getAll());
    },
    getAllVillages: () => {
      dispatch(villageActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        hotels: state.hotels,
        villages: state.villages,
        sessionData: state.session,
        isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
    }
}

const connectedAllHotelCards = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(AllHotelCards));
export { connectedAllHotelCards as AllHotelCards };
