import React from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";

const FormikDatePicker = ({
    options,
    field,
    form,
    label,
    error,
    withHr,
    touched,
    handleBlur
  }) => (
      <DatePicker
      selected={field.value}
      onSelect={(value) => form.setFieldValue(field.name, value)}
      onChange={(value) => form.setFieldValue(field.name, value)}
      value={field.value}
      onBlur={field.onBlur}
      withPortal
      />
    )

export { FormikDatePicker };
