import { hotelConstants } from '../_constants';
import { hotelService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const hotelActions = {
    getAll,
    createNew,
    update,
    delete: _delete
  };


function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        hotelService.getAll(1, 10, "")
            .then(
                hotels => {
                    console.log("THEN SUCCESS");
                    dispatch(success(hotels))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: hotelConstants.GETALL_REQUEST } }
    function success(hotels) { return { type: hotelConstants.GETALL_SUCCESS, hotels } }
    function failure(error) { return { type: hotelConstants.GETALL_FAILURE, error } }
}

function createNew(hotel)  {
    return dispatch => {
        dispatch(request(hotel));

        hotelService.create(hotel)
            .then(
                hotel => {
                    dispatch(success(hotel.data));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: hotelConstants.CREATE_REQUEST, hotel } }
    function success(id) { return { type: hotelConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: hotelConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return hotelService.update(edit)
            .then(
                hotel => {
                    dispatch(success(hotel));
                    return hotel;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: hotelConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: hotelConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: hotelConstants.UPDATE_FAILURE, id, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        hotelService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: hotelConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: hotelConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: hotelConstants.DELETE_FAILURE, id, error } }
}
