import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store, history } from '../_helpers';

export const hotelService = {
    getAll,
    update,
    create,
    delete: _delete
};

function getAll() {

    const config = {
      headers: { ...authHeader() }
    };

    return axios.get('/hotels', config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load hotels. Please try again later.")); throw "failure";});
}

function get(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/hotel/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}

function update(edit) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/hotel', edit, config).then(handleResponse).catch(error => {console.log(error.response)});
}

function create(values) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log(values);
    return axios.put('/hotel', values, config).then(handleResponse).catch(error => {console.log(error.response)});
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/hotel/' + id, requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}
