import React from 'react';
import {
  Card as BootstrapCard, CardImg, CardBody,
  CardTitle, Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Card extends React.Component {

  render() {
    const { t } = this.props;

    return (
      <div>
        <BootstrapCard>
          <CardImg top width="100%" src={this.props.picture?this.props.picture:"https://via.placeholder.com/318x180.png"} />
          <CardBody>
            <CardTitle>{this.props.title}</CardTitle>
            <p style={{color: this.props.urg_tasks < 2 ? 'green' : this.props.urg_tasks < 4 ? "orange" : "red"}}>{this.props.num_tasks} active tasks</p>
            <Button tag={Link} to={"/tasks/"+this.props.value}>{t("See all tasks")}</Button>
          </CardBody>
        </BootstrapCard>
      </div>
		)
	}
}

const trCard = withTranslation("translation")(Card);
export { trCard as Card };
