import React from 'react';
import { connect } from 'react-redux';
import { categoryActions } from '../_actions';
import { NewCategoryModal } from "../Categories"
import { Form, Row, Col } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';

function selectDataFormatterLabel(cell, row) {
  return <span>{row.label}</span>
}



const remote={
  filter: false,
  pagination: false,
  sort: false,
  cellEdit: true
}

class AllCategories extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        categories: []
      };
    }

  componentDidMount() {
    this.props.getAllCategories();
  }

  onTableChange(type, newState) {
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    return true;
  }

  render() {
    const { t } = this.props;

    const columns = [{
      dataField: 'label',
      text: t('Category'),
      editable: true
    }];

    return (
      <div style={{marginLeft:"5%", marginRight:"5%"}}>
        <Row>
          <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewCategoryModal buttonLabel={t("New task category")} /></Col><Col md="1" xs="6"></Col>
        </Row>
        <br/>
        { this.props.categories && this.props.categories.items && this.props.categories.items.length > 0 &&
          <div className="table-responsive-sm">
            <BootstrapTable style={{ margin:"auto"}} bootstrap4={true} bordered={false} keyField='value' remote={remote} cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) } onTableChange={this.onTableChange.bind(this)} data={ this.props.categories.items } columns={ columns } />
          </div>
        }
      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(categoryActions.update(edit));
    },
    getAllCategories: () => {
      dispatch(categoryActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        categories: state.categories
    }
}

const connectedAllCategories = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(AllCategories));
export { connectedAllCategories as AllCategories };
