import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './_helpers';
import './css/index.css';
import './css/App.css';
import './css/custom.scss';
import { Container } from './App/Container';
import axios from "axios";
import RichTextEditor from 'react-rte';
import registerServiceWorker from './utils/registerServiceWorker';

window.RichTextEditor = RichTextEditor

axios.defaults.baseURL = 'https://maintenance-server.fcnqreservation.ca/';

//axios.defaults.baseURL = 'http://localhost:8014/';

ReactDOM.render(
  <Provider store={store}>
    <Container />
  </Provider>, document.getElementById('root'));
registerServiceWorker();
