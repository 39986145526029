import React, { Component } from 'react';
import {Row, Col, Navbar, NavbarToggler, NavbarBrand, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { withTranslation } from 'react-i18next';
import i18n from '../i18n';
import { store } from '../_helpers';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.state = {
      isOpen: false
    };
  }

  changeLanguage(e) {
      const new_language = sessionStorage.getItem('language') == "en"?"fr":"en";
      i18n.changeLanguage(new_language);
      sessionStorage.setItem('language', new_language);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    const { t, language } = this.props;
    const language_options = [{label:t("English"), value:"en"}, {label:t("French"),value:"fr"}]


    return (
      <div style={{borderBottom:"1px solid #e5e5e5"}} className="Navigation">
          <Row>
            <Navbar style={{marginLeft: "50px", marginRight:"50px", width:"100%"}} light expand="lg">
              <NavbarBrand style={{weight:"bold"}} href="/" >FCNQ Construction</NavbarBrand>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav navbar>
                { this.props.isAuthenticated && this.props.sessionData.user && this.props.sessionData.user.is_admin &&
                  <><NavItem>
                    <NavLink tag={Link} to="/tasks">{t("Tasks")}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/users">{t("Users")}</NavLink>
                  </NavItem>
                  </>
                  }
                  <NavItem>
                    <NavLink onClick={this.changeLanguage}>{language=="fr"?"Anglais":"French"}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/logout">{t("Logout")}</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </Navbar>
          </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sessionData: state.session,
  isAuthenticated: state.session && state.session.user && state.session.user.authentication_token?true:false
})

const connectedNavigation = withTranslation("translation")(connect(mapStateToProps)(Navigation));
export { connectedNavigation as Navigation };
