import React from 'react';
import { connect } from 'react-redux';
import { userActions, taskActions, hotelActions, categoryActions, imageActions } from '../_actions';
import { Select as TableSelect } from "../_components"
import { Row, Col } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import { NewTaskModal, ResolveTaskModal, EditTaskModal, DeleteTaskModal } from '../Tasks';
import filterFactory, { textFilter, dateFilter, numberFilter, selectFilter, customFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { withTranslation } from 'react-i18next';

const rowStyle = (row, rowIndex) => {
  const style = {};
  if (row.value.archived) {
    style.backgroundColor = '#FF7373';
  }
  return style;
};



function categoryFormatter(cell, row) {
    if (row.categories.items && row.categories.items.length > 0)
    {
      var value = row.categories.items.find(option => option.value == row.value.category_id)
      if (value)
      {
        return (
          <span>{ value.label }</span>
        );
      }
      else {
        return (<span></span>);
      }
    }
    else {
      return (<span></span>);
    }
}

function selectDataFormatterSubmittedBy(cell, row) {
    if (row.users.items && row.users.items.length > 0)
    {
      var value = row.users.items.find(option => option.id == row.value.submitted_by)
      if (value)
      {
        return (
          <span>{ value.label }</span>
        );
      }
      else {
        return (<span></span>);
      }
    }
    else {
      return (<span></span>);
    }
}

const remote={
  filter: true,
  pagination: true,
  sort: false,
  cellEdit: false
}

class AllTasks extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        tasks: []
      };
    }

  componentDidMount() {
    let hotel_id = this.props.match.params.hotel_id;
    console.log("HOTEL ID");
    console.log(hotel_id);
    this.props.getAll(1, 10, {'value.hotel_id':{ filterType: "TEXT", comparator: '==', filterVal: hotel_id, caseSensitive: false} });
    this.props.getAllHotels();
    this.props.getAllCategories();
    this.props.getAllUsers();
    this.props.getAllImages();

  }

  onTableChange(type, newState) {
    if(type==="cellEdit")
    {
      this.props.handleChange(newState.cellEdit);
    }
    else if (type==="pagination" || type==="filter") {
      console.log(type)
      console.log(newState)
      let hotel_id = this.props.match.params.hotel_id;
      if (hotel_id) {
        newState.filters['value.hotel_id'] = {filterVal: hotel_id, filterType: "TEXT", comparator: "==", caseSensitive: false};
      }

      this.props.getAll(newState.page, newState.sizePerPage, newState.filters)
    }
    return true;
  }

  render() {
    const { t } = this.props;

    function editLinkFormatter(value, row, index, field) {
      if(!row.value.resolution) {
        return (
          <EditTaskModal initialValues={row.value} task_id={row.value.task_id} buttonLabel="Edit" />
        );
      }
      else {
        return <span></span>
      }
    }

    function deleteLinkFormatter(value, row, index, field) {
      if(!row.value.resolution) {
        return (
          <DeleteTaskModal initialValues={row.value} task_id={row.value.task_id} buttonLabel="Edit" />
        );
      }
      else {
        return <span></span>
      }
    }

    function resolveLinkFormatter(value, row, index, field) {
      if(!row.value.resolution) {
        return (
          <ResolveTaskModal initialValues={row.value} task_id={row.value.task_id} buttonLabel="Resolve" />
        );
      }
      else {
        return <span>Resolved on {row.value.resolution_date?row.value.resolution_date.substring(0,11):""}. {row.value.resolution}</span>
      }
    }

    var columns = [{
      dataField: 'value.task_id',
      text: '#',
      filter: textFilter({placeholder: t('Filter...')})
    }, {
      dataField: 'value.category',
      text: t('Category'),
      formatter: categoryFormatter,
      filter: textFilter({placeholder: t('Filter...')}),
      filterValue: (cell, row) => row.categories && row.categories.items.find(option => option.value == cell).label
    }, {
      dataField: 'value.description',
      text: t('Description'),
      editable: false,
      filter: textFilter({placeholder: t('Filter...')})
    },{
      dataField: 'value.update',
      text: t('Update'),
      editable: false,
      filter: textFilter({placeholder: t('Filter...')})
    }, {
      dataField: 'value.submitted_by',
      text: t('Requested by'),
      formatter: selectDataFormatterSubmittedBy,
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
          <TableSelect options={row.users.items} { ...editorProps } value={ row.value } />
      ),
      filter: textFilter({placeholder: t('Filter...')}),
      filterValue: (cell, row) => row.users && row.users.items.find(option => option.id == cell).label
    },{
      dataField: 'value.resolution_estimate',
      text: t('Resolution Estimate'),
      editable: false,
      filter: dateFilter({placeholder: 'Filter...'})
    },{
      dataField: 'value.status',
      text: t('Status'),
      editable: false,
      filter: selectFilter({options:[{value:"New", label:"New"},{value:"In Progress", label:"In Progress"},{value:"Completed", label:"Completed"}]}),
    }];

    if (!this.props.is_read_only) {
      console.log("NOT READ ONLY ADDING COLUMNS")
      console.log(columns)

      columns = columns.concat([{
        text: t('Edit'),
        editable: false,
        formatter: editLinkFormatter
      }, {
        text: t('Resolve'),
        editable: false,
        formatter: resolveLinkFormatter
      }, {
        text: t('Resolve'),
        editable: false,
        formatter: deleteLinkFormatter
      }])

      console.log("NOW")
      console.log(columns)
    }
    

    var data = []
    console.log(data);
    if (this.props.tasks && this.props.tasks.items && this.props.tasks.items.length > 0 )
    {
      for (var i in this.props.tasks.items)
      {
        data.push({task_id: this.props.tasks.items[i].task_id, value:this.props.tasks.items[i], categories:this.props.categories, users:this.props.users})
      }
    }
    data.sort(function(a, b){return b.task_id - a.task_id});
    console.log(data);
    var pageFactory = paginationFactory({ page:this.props.tasks.pagination.page, sizePerPage:this.props.tasks.pagination.sizePerPage, totalSize:this.props.tasks.pagination.totalSize })

    return (
      <div style={{marginLeft:"5%", marginRight:"5%", width:"100%"}}>
        { !this.props.is_read_only &&
          <Row>
            <Col md="3" xs="6" style={{marginBottom: "10px"}}><NewTaskModal hotelId={this.props.match.params.hotel_id} buttonLabel="New task request" /></Col><Col md="1" xs="6"></Col><Col md="8" xs="12"></Col>
          </Row>
        }
        
        <br/>
        {data.length == 0 &&
          <h5>{t("There are no active tasks for this hotel.")}</h5>
        }
        {data.length > 0 &&
          <p>{t("Please make sure the task is not already listed before requesting it. If it is listed you can add information to it by clicking the Edit button.")}</p>
        }
          <BootstrapTable pagination={ pageFactory } filter={ filterFactory() } rowStyle={rowStyle} bootstrap4={true} bordered={false} classes={"table-responsive"} keyField='task_id' remote={remote} onTableChange={this.onTableChange.bind(this)} data={ data } columns={ columns } />

      </div>
    )

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleChange: (edit) => {
      dispatch(taskActions.update(edit));
    },
    getAll: (page, numPerPage, searchQuery) => {
      dispatch(taskActions.getAll(page, numPerPage, searchQuery));
    },
    getAllHotels: () => {
      dispatch(hotelActions.getAll());
    },
    getAllCategories: () => {
      dispatch(categoryActions.getAll());
    },
    getAllUsers: () => {
      dispatch(userActions.getAll());
    },
    getAllImages: () => {
      dispatch(imageActions.getAll());
    }
  }
}

function mapStateToProps(state){
    return {
        users: state.users,
        tasks: state.tasks,
        categories: state.categories,
        images: state.images,
        is_read_only: state.session && state.session.user && state.session.user.is_read_only,
    }
}

const connectedAllTasks = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(AllTasks));
export { connectedAllTasks as AllTasks };
