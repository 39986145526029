export const hotelConstants = {

    GETALL_REQUEST: 'HOTELS_GETALL_REQUEST',
    GETALL_SUCCESS: 'HOTELS_GETALL_SUCCESS',
    GETALL_FAILURE: 'HOTELS_GETALL_FAILURE',

    DELETE_REQUEST: 'HOTELS_DELETE_REQUEST',
    DELETE_SUCCESS: 'HOTELS_DELETE_SUCCESS',
    DELETE_FAILURE: 'HOTELS_DELETE_FAILURE',

    CREATE_REQUEST: 'HOTEL_CREATE_REQUEST',
    CREATE_SUCCESS: 'HOTEL_CREATE_SUCCESS',
    CREATE_FAILURE: 'HOTEL_CREATE_FAILURE',
    UPDATE_REQUEST: 'HOTEL_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'HOTEL_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'HOTEL_UPDATE_FAILURE',

};
