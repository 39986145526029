export const taskConstants = {
    GETALL_REQUEST: 'TASKS_GETALL_REQUEST',
    GETALL_SUCCESS: 'TASKS_GETALL_SUCCESS',
    GETALL_FAILURE: 'TASKS_GETALL_FAILURE',
    DELETE_REQUEST: 'TASK_DELETE_REQUEST',
    DELETE_SUCCESS: 'TASK_DELETE_SUCCESS',
    DELETE_FAILURE: 'TASK_DELETE_FAILURE',
    CREATE_REQUEST: 'TASK_CREATE_REQUEST',
    CREATE_SUCCESS: 'TASK_CREATE_SUCCESS',
    CREATE_FAILURE: 'TASK_CREATE_FAILURE',
    UPDATE_REQUEST: 'TASK_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'TASK_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'TASK_UPDATE_FAILURE',
    SELECT: 'TASK_SELECT',
    UPDATE_SEARCH: 'TASK_UPDATE_SEARCH',
    UPDATE_PAGINATION: 'TASK_UPDATE_PAGINATION',
};
