import { taskConstants } from '../_constants';
import { taskService } from '../_services';
import { error as toastError} from 'react-toastify-redux';
import { history } from '../_helpers';

export const taskActions = {
    getAll,
    refreshAll,
    updateSearch,
    updatePagination,
    createNew,
    update,
    delete: _delete
  };

function _delete(id, reason) {
    return dispatch => {
        dispatch(request(id, reason));

        taskService.delete(id, reason)
            .then(
                user => {
                    dispatch(success(id, reason));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: taskConstants.DELETE_REQUEST, id, reason } }
    function success(id) { return { type: taskConstants.DELETE_SUCCESS, id, reason } }
    function failure(id, error) { return { type: taskConstants.DELETE_FAILURE, id, error } }
}

function getAll(page, numPerPage, searchQuery) {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        taskService.getAll(page, numPerPage, searchQuery)
            .then(
                tasks => {
                    console.log("THEN SUCCESS");
                    dispatch(success(tasks))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: taskConstants.GETALL_REQUEST } }
    function success(tasks) { return { type: taskConstants.GETALL_SUCCESS, tasks } }
    function failure(error) { return { type: taskConstants.GETALL_FAILURE, error } }
}

function changeFilter(filters) {
    return { type: taskConstants.CHANGE_FILTER, filters };
}

function refreshAll(page, sizePerPage, searchQuery) {
    return dispatch => {
        taskService.getAll(page, sizePerPage, searchQuery)
            .then(
                tasks => dispatch(success(tasks)),
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function success(tasks) { return { type: taskConstants.GETALL_SUCCESS, tasks } }
    function failure(error) { return { type: taskConstants.GETALL_FAILURE, error } }
}

function updateSearch(searchQuery) {
    return { type: taskConstants.UPDATE_SEARCH, searchQuery }
}

function updatePagination(page, sizePerPage) {
    return { type: taskConstants.UPDATE_PAGINATION, page, sizePerPage }
}

function createNew(task)  {
    return dispatch => {
        dispatch(request(task));

        taskService.create(task)
            .then(
                task => {
                    dispatch(success(task));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: taskConstants.CREATE_REQUEST, task } }
    function success(id) { return { type: taskConstants.CREATE_SUCCESS, id } }
    function failure(id, error) { return { type: taskConstants.CREATE_FAILURE, id, error } }
}

function update(edit)  {
    return dispatch => {
        dispatch(request(edit));

        return taskService.update(edit)
            .then(
                task => {
                    dispatch(success(task));
                    return task;
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: taskConstants.UPDATE_REQUEST, edit } }
    function success(id) { return { type: taskConstants.UPDATE_SUCCESS, id } }
    function failure(id, error) { return { type: taskConstants.UPDATE_FAILURE, id, error } }
}
