import React, { Component, Suspense } from 'react';
import '../css/App.css';
import { history } from '../_helpers';
import { userActions } from '../_actions';
import { Router, Route, Redirect } from 'react-router-dom';
import { LoginPage, ChangePasswordPage, ForgotPasswordPage, ResetPasswordPage, LogoutPage } from '../AuthPages';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from '@sentry/browser';
import { ToastContainer, error as toastError, success as toastSuccess  } from 'react-toastify-redux';
import { Navigation, PrivateRoute, PrivateAdminRoute, LoginRoute } from '../_components';
import { connect } from 'react-redux';
import { AllTasks } from '../Tasks';
import { AllHotelCards } from '../Hotels';
import { AllUsers } from '../Users';
import { AllCategories } from '../Categories';
import { withTranslation } from 'react-i18next';
import { App } from './App'

Sentry.init({
  dsn: "https://6c6750aed2c241bc989e641c9cda1601@sentry.io/1358046"
 });

class Container extends Component {
  constructor(props) {
      super(props);

      const { dispatch } = this.props;
  }

  render() {

    const lsUser = JSON.parse(localStorage.getItem('user'));

    return (
      <Suspense fallback='loading'>
        <div className="App">
          <Router history={history}>
            <App/>
          </Router>
        </div>
      </Suspense>

    );
  }
}

export { Container };
