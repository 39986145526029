import { hotelConstants } from '../_constants';

export function hotels(state = {items: []}, action) {
  switch (action.type) {
    case hotelConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case hotelConstants.GETALL_SUCCESS:
      return {
        items: action.hotels.data
      };
    case hotelConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case hotelConstants.DELETE_REQUEST:
      // add 'deleting:true' property to hotel being deleted
      return {
        ...state,
        items: state.items.map(hotel =>
          hotel.id === action.id
            ? { ...hotel, deleting: true }
            : hotel
        )
      };
    case hotelConstants.DELETE_SUCCESS:
      // remove deleted hotel from state
      return {
        items: state.items.filter(hotel => hotel.id !== action.id)
      };
    case hotelConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to hotel
      return {
        ...state,
        items: state.items.map(hotel => {
          if (hotel.id === action.id) {
            // make copy of hotel without 'deleting:true' property
            const { deleting, ...hotelCopy } = hotel;
            // return copy of hotel with 'deleteError:[error]' property
            return { ...hotelCopy, deleteError: action.error };
          }

          return hotel;
        })
      };
    case hotelConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (hotel, index) => {
            if(hotel.value === action.edit.rowId){
              return { ...hotel, [action.edit.dataField]:action.edit.newValue }
            }
            return hotel
          })
      };
    case hotelConstants.CREATE_SUCCESS:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: [
            ...state.items,
            action.id
        ]
      };
    default:
      return state
  }
}
