import { combineReducers } from 'redux';
import { hotels } from './hotels.reducer';
import { categories } from './categories.reducer';
import { villages } from './villages.reducer';
import { users } from './users.reducer';
import { images } from './images.reducer';
import { tasks } from './tasks.reducer';
import { session } from './session.reducer';
import { toastsReducer as toasts } from 'react-toastify-redux';

const rootReducer = combineReducers({
  session,
  users,
  tasks,
  toasts,
  categories,
  hotels,
  images,
  villages
});

export default rootReducer;
