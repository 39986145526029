import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store, history } from '../_helpers';

export const imageService = {
    getAll,
    upload,
    delete: _delete
};

function getAll(task_id) {

    const config = {
      headers: { ...authHeader() }
    };

    return axios.get('/images', config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load tasks. Please try again later.")); throw "failure";});
}

function upload(values, task_id) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log(values);
    if (task_id) {
        return axios.put('/image/'+task_id , values, config).then(handleResponse).catch(error => {console.log(error.response)});
    }
    else {
        return axios.put('/image' , values, config).then(handleResponse).catch(error => {console.log(error.response)});
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, reason) {
    const requestOptions = {
        headers: authHeader(),
        data: {id: id}
    };

    return axios.delete('/image', requestOptions).then(handleResponse).catch(error => {console.log(error.response)});
}