import { imageConstants, taskConstants } from '../_constants';
import { imageService } from '../_services';
import { history } from '../_helpers';

export const imageActions = {
    upload,
    getAll
};


function upload(image, task_id) {
    return dispatch => {
        dispatch(request({ image, task_id }));
        console.log("AFTER DISPATCH");
        console.log(image.entries());
        imageService.upload(image, task_id)
            .then(
                image => {
                    if(image)
                    {
                      console.log("THEN IMAGE")
                      console.log(image)
                      dispatch(success(image, task_id));
                    }
                    else {
                      console.log("THEN ERROR")
                      dispatch(failure("Failed uploading image."));
                      //dispatch(alertActions.error("Failed uploading image."));
                    }
                },
                error => {
                    console.log("THEN ERROR")
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(image) { return { type: imageConstants.UPLOAD_REQUEST, image, task_id } }
    function success(image) { return { type: imageConstants.UPLOAD_SUCCESS, image, task_id } }
    function failure(error) { return { type: imageConstants.UPLOAD_FAILURE, error } }
}

function getAll() {
    return dispatch => {

        dispatch(request());
        console.log("REQUESTED")
        imageService.getAll()
            .then(
                images => {
                    console.log("THEN SUCCESS");
                    dispatch(success(images))
                }
            ).catch(
                error => {
                    console.log("THEN ERROR");
                    dispatch(failure(error.error));
                    //dispatch(toastError(error));
            });
    };

    function request() { return { type: imageConstants.GETALL_REQUEST } }
    function success(images) { return { type: imageConstants.GETALL_SUCCESS, images } }
    function failure(error) { return { type: imageConstants.GETALL_FAILURE, error } }
}