import { imageConstants } from '../_constants';

export function images(state = {items: []}, action) {
  switch (action.type) {
    case imageConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case imageConstants.GETALL_SUCCESS:
      return {
        items: action.images.data
      };
    case imageConstants.GETALL_FAILURE:
      return {
        error: action.error
      };
    case imageConstants.DELETE_REQUEST:
      // add 'deleting:true' property to category being deleted
      return {
        ...state,
        items: state.items.map(category =>
          category.id === action.id
            ? { ...category, deleting: true }
            : category
        )
      };
    case imageConstants.DELETE_SUCCESS:
      // remove deleted category from state
      return {
        items: state.items.filter(category => category.id !== action.id)
      };
    case imageConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to category
      return {
        ...state,
        items: state.items.map(category => {
          if (category.id === action.id) {
            // make copy of category without 'deleting:true' property
            const { deleting, ...categoryCopy } = category;
            // return copy of category with 'deleteError:[error]' property
            return { ...categoryCopy, deleteError: action.error };
          }

          return category;
        })
      };
    case imageConstants.UPDATE_REQUEST:
      console.log("ACTION")
      console.log(action)
      return {
        ...state,
        items: state.items.map( (image, index) => {
            if(image.image_id === action.edit.rowId){
              return { ...image, [action.edit.dataField]:action.edit.newValue }
            }
            return image
          })
      };
    case imageConstants.UPLOAD_SUCCESS:
    console.log("ACTION")
    console.log(action)
    return {
        ...state,
        items: [
            ...state.items,
            action.image.data
        ]
      };
    default:
      return state
  }
}
