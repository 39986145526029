import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, Row, Col, FormFeedback, Table } from 'reactstrap';
import { taskActions } from '../_actions';
import BootstrapTable from 'react-bootstrap-table-next';
import { history } from '../_helpers';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik'
import { FormikSelect as Select, FormikDatePicker, RadioButton, RadioButtonGroup, ImageUploader } from '../_components';
import ReactSelect from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import _ from 'lodash'
import { withTranslation } from 'react-i18next';

const isRequired = message => value => (!!value ? undefined : message);

class EditTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
    this.updateTask = this.updateTask.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  updateTask(values) {
    console.log(this);
    console.log(this.props);

    this.props.handleSubmit({...values, task_id:this.props.task_id})
    this.toggle();
    // resetForm();
  }

  render() {
    const { t } = this.props;
    const hotels = [];
    if (this.props.hotels && this.props.hotels.items) {
      this.props.hotels.items.forEach((item, i) => {
        const village = this.props.villages.items.find(function(v) {
          return v.value == item.village;
        });

        var full_name = item.label;

        if (village) {
          full_name = item.label+" - "+village.label;
        }
        hotels.push({...item, label:full_name})
      });
    }

    return (
      <div>
        <Button color="success" className="full-width" onClick={this.toggle}>{this.props.buttonLabel}</Button>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader><h4>{t("Make a task request")}</h4></ModalHeader>
          <ModalBody>
          <Formik
            ref="formikForm"
            onSubmit={values => {
              this.updateTask(values);
            }}
            initialValues={{
              category_id: this.props.initialValues.category_id,
              description: this.props.initialValues.description,
              update: this.props.initialValues.update,
              degree_of_urgency: this.props.initialValues.degree_of_urgency.toString(),
              images:this.props.initialValues.images,
              resolution_estimate:this.props.initialValues.resolution_estimate,
              hotel_id: this.props.hotel_id?this.props.hotel_id:this.props.initialValues.hotel_id
          	}}
            validationSchema={Yup.object().shape({
              category_id: Yup.number()
                .required(t('You must pick a category.')),
              description: Yup.string()
                .required(t('Please describe the issue.')),
              update: Yup.string()
                .required(t('Please describe how this task is progressing.')),
              degree_of_urgency: Yup.number()
                .required(t('Please tell us how urgent this issue is.')),
              resolution_estimate: Yup.date()
            })}
            render={({values, handleChange, handleBlur, handleSubmit, touched, errors, isSaving, lastSaved, saveError, setFieldValue, setFieldTouched, isSubmitting }) =>
              <Form>
                <FormGroup>
                  <Field handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Category" options={this.props.categories.items} name="category_id" id="category_id" value={values.category_id}/>
                  {errors.category_id && touched.category_id && <div className="input-feedback" style={{color:"red"}}>{errors.category_id}</div>}
                </FormGroup>
                <FormGroup>
                  <Label for="description">{t("Description")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.description && touched.description} tag={Field} type="text" value={values.description} name="description" id="description" />
                  {errors.description && touched.description && <div className="input-feedback" style={{color:"red"}}>{errors.description}</div>}
                </FormGroup>
                <ImageUploader values={values.images} task_id={this.props.task_id}></ImageUploader>
                <RadioButtonGroup
                  id="degree_of_urgency"
                  label={t("Degree of urgency")}
                  value={values.degree_of_urgency}
                  error={errors.degree_of_urgency}
                  touched={touched.degree_of_urgency}
                >
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="1"
                    label="1"
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="2"
                    label="2"
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="3"
                    label="3"
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="4"
                    label="4"
                  />
                  <Field
                    component={RadioButton}
                    name="degree_of_urgency"
                    id="5"
                    label="5"
                  />
                </RadioButtonGroup>
                <FormGroup>
                  <Field isDisabled={parseInt(this.props.hotelId)} handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} component={Select} label="Building" options={hotels} name="hotel_id" id="hotel_id" value={values.hotel_id}/>
                  {errors.hotel_id && touched.hotel_id && <div className="input-feedback" style={{color:"red"}}>{errors.hotel_id}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="resolution_estimate">{t("Resolution Estimate")}</Label>
                    <Field isDisabled={this.props.isAuthenticated && this.props.sessionData.user && this.props.sessionData.user.is_admin} component={FormikDatePicker}  handleBlur={handleBlur} onChange={(e) => {handleChange(e)}} name='resolution_estimate' id='resolution_estimate' />
                </FormGroup>
                <FormGroup>
                  <Label for="update">{t("Update")}</Label>
                  <Input onChange={(e) => {handleChange(e)}} invalid={errors.update && touched.update} tag={Field} type="text" value={values.update} name="update" id="update" />
                  {errors.update && touched.update && <div className="input-feedback" style={{color:"red"}}>{errors.update}</div>}
                </FormGroup>
                <Row>
                  <Col xs="6" className="text-center">
                    <Button type="submit" color="primary" onClick={(e) => {handleSubmit(e);}} disabled={isSubmitting}>{t("Add")}</Button>
                  </Col>
                  <Col xs="6" className="text-center" >
                    <Button color="secondary" onClick={this.toggle}>{t("Cancel")}</Button>
                  </Col>
                </Row>
              </Form>}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubmit: (task) => {
      dispatch(taskActions.update(task));
    }
  }
}

function mapStateToProps(state, props) {
  const { users, hotels, categories, images, villages } = state;
  return {
      categories: categories,
      users: users,
      hotels: hotels,
      villages: villages,
      images: _.filter(images.items, { 'task_id': props.task_id?parseInt(props.task_id):null } )
  };
}

const connectedEditTaskModal = withTranslation("translation")(connect(mapStateToProps, mapDispatchToProps)(EditTaskModal));
export { connectedEditTaskModal as EditTaskModal };
