import React from 'react';
import {
  FormGroup
} from 'reactstrap';
import classNames from "classnames";

// Input feedback
const InputFeedback = ({ error }) =>
  error ? <div className={classNames("input-feedback")}>{error}</div> : null;

// Radio group
const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched), // handle prefilled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <div className={classes}>
      {label}
      <br/>
      <FormGroup check inline>
        {children}
        {touched && <InputFeedback error={error} />}
      </FormGroup>
    </div>
  );
};

export { RadioButtonGroup };
