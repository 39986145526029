export const categoryConstants = {

    GETALL_REQUEST: 'CATEGORIES_GETALL_REQUEST',
    GETALL_SUCCESS: 'CATEGORIES_GETALL_SUCCESS',
    GETALL_FAILURE: 'CATEGORIES_GETALL_FAILURE',

    DELETE_REQUEST: 'CATEGORIES_DELETE_REQUEST',
    DELETE_SUCCESS: 'CATEGORIES_DELETE_SUCCESS',
    DELETE_FAILURE: 'CATEGORIES_DELETE_FAILURE',

    CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
    CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
    CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',
    UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',

};
